import React from 'react';
import { graphql } from 'gatsby';
import Path from '../../services/path';
import { prepareEpg } from '../../services/station-helper';
import Layout from '../../components/layout/layout';
import SEO from '../../components/seo';
import StationScheduleDay from '../../components/station/station-schedule-day';
import SlimlineBannerBlock from '@bfbs/storybook/dist/components/molecules/slimline-banner-block/slimline-banner-block';
import ScheduleTabNavigation from '@bfbs/storybook/dist/components/molecules/schedule-navigation-tab/schedule-navigation-tab';
import { useState } from 'react';

const StationSchedule = ({ data: { station, epg } }) => {
  if (!!epg) {
    const [currentTab, setCurrentTab] = useState(0);
    const [currentBroadcasts, setCurrentBroadcasts] = useState(prepareEpg(epg.days)[0].broadcasts);

    const breadcrumbs = [
      {name: 'Radio', path: Path.basePath},
      {name: 'All stations', path: Path.stationList},
      {name: station.title, path: Path.getStationPath(station.title)},
      {name: 'Schedule', path: null},
    ];
    const tabChangeHandler = e => {
      setCurrentTab(e);
      setCurrentBroadcasts(prepareEpg(epg.days)[e].broadcasts);
    };

    return (
      <Layout breadcrumbs={breadcrumbs}>
        <SEO title="schedule"/>

        <SlimlineBannerBlock
          background="background--tertiary-two"
          heading={station.title}
          icon="radio-white"
          image={station.metadatastation.logo}
          subtitle="Schedule"
        />
        <div className="o-schedule">
          <ScheduleTabNavigation
            navigationTabData={epg.days.map(d => d.day.day)}
            selectedTab={currentTab}
            tabChangeHandler={tabChangeHandler}
          />
          {currentBroadcasts && (
            <StationScheduleDay
              broadcastList={currentBroadcasts}
              stationListenLiveUrl={Path.getStationPlayerUrl(station.id)}
            />
          )}
        </div>
      </Layout>
    );
  }
  else {
    return (null);
  }
};

export default StationSchedule;

export const stationScheduleQuery = graphql`
  query StationScheduleQuery($station_id: String!, $today: Int!) {
    station(bfbs_id: { eq: $station_id }) {
      title
      id
      metadatastation {
        logo: svg1by1
      }
    }

    epg(station_id: { eq: $station_id }, start_date: { gte: $today }) {
      days {
        day {
          day: date(formatString: "dddd")
          timestamp: date(formatString: "X")
        }
        broadcasts {
          id
          name
          title
          description
          presenter {
            title
            name
            field_values {
              image
            }
          }
          start {
            date
            timezone
          }
          stop {
            date
            timezone
          }
        }
      }
    }
  }
`;
