import React from 'react';
import _ from 'lodash';
import '@bfbs/storybook/dist/components/molecules/schedule-daily-information/schedule-daily-information.scss';
import ScheduleDailyInformationItem from '@bfbs/storybook/dist/components/molecules/schedule-daily-information/schedule-daily-information-item';
import {prepareTimezone, now} from "../../services/time";

const StationScheduleDay = ({ broadcastList, stationListenLiveUrl }) => {
  /**
   * Order the broadcasts by their timestamp.
   *
   * @param broadcasts
   */
  const orderBroadcasts = broadcasts => {
    return _.sortBy(broadcasts, broadcast => {
      return broadcast.start.timestamp;
    });
  };

  /**
   * Remove duplicates
   * @param broadcasts
   * @returns {Array}
   */
  const deDuplicate = broadcasts => {
    return _.sortedUniqBy(broadcasts, broadcast => {
      return broadcast.id;
    });
  };

  /**
   * Is the broadcast over?
   *
   * @param stop The time object.
   * @param stop.date The full date string
   * @param stop.timezone The timezone diff.0
   */
  const showOver = stop => {
    const stopTime = prepareTimezone(stop);
    return now().isAfter(stopTime);
  };

  /**
   * Sort broadcasts and remove duplicates.
   *
   * @type {Array}
   */
  const broadcasts = deDuplicate(orderBroadcasts(broadcastList));

  return (
    <div className="m-schedule-daily-information">
      <ul className="m-schedule-daily-information__list list--plain" role="menu">
        {broadcasts.map((show, index) => {
          return (
            !showOver(show.stop) && (
              <ScheduleDailyInformationItem
                key={index.toString()}
                {...show}
                listenLiveUrl={stationListenLiveUrl}
                fallbackImg="https://download-ssvc.sharp-stream.com/images/gurkha_default.jpg"
              />
            )
          );
        })}
      </ul>
    </div>
  );
};

export default StationScheduleDay;
